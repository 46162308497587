<template>
  <div>
    <Top1 />
    <div :style="bg" class="contentBG">
      <div class="formWrap">
        <!-- tab -->
        <div class="tabWrap">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="用户密码登录" name="1">
              <LoginForm1 :refresh="refresh" />
            </el-tab-pane>
            <el-tab-pane label="短信验证码登录" name="2">
              <LoginForm2 :refresh="refresh" />
            </el-tab-pane>
          </el-tabs>
          <div>
            <el-link type="info" @click="toForget">忘记密码？</el-link
            ><el-link type="success" @click="toRegister">注册新用户</el-link>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Top1 from "@/components/login/Top1.vue";
import Foot from "@/components/login/Foot.vue";
import LoginForm1 from "@/components/login/LoginForm1.vue";
import LoginForm2 from "@/components/login/LoginForm2.vue";
export default {
  components: {
    Top1,
    Foot,
    LoginForm1,
    LoginForm2,
  },
  data() {
    return {
      refresh: true,
      activeName: "1",
      bg: {
        backgroundImage:
          "url(" + require("../assets/images/dlimg.dcea5b2.jpg") + ")",
      },
    };
  },
  methods: {
    handleClick(tab) {
      //refresh img
  
      this.refresh = !this.refresh;
    },
    toForget() {
      this.$router.push("/forget");
    },
    toRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped>
.contentBG {
  height: 606px;
}
.formWrap {
  width: 1200px;
  height: 606px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.tabWrap {
  width: 350px;
  height: 325px;
  background: white;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 60px;
}
</style>