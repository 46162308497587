<template>
  <div class="wrap">
    <span>Copyright ©中惠保险经纪 All Rights Reserved</span>
    <span
      >浙ICP备：
      <el-link :underline="false" href="http://beian.miit.gov.cn/"
        >15013235号-3</el-link
      >
    </span>
    <img
      src="https://www.zhonghuicn.com/Public/images/police-icon.png"
      alt=""
      width="16"
      height="16"
      style="margin-bottom: -2px"
    />
    <span
      >浙公网安备：

      <el-link
        :underline="false"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003611"
        >33010302003611</el-link
      >
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrap {
  color: #999;
  font-size: 12px;
  line-height: 66px;
}
.wrap span {
  margin: 0 6px;
}
</style>