<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-form-item prop="name">
      <el-input
        v-model="ruleForm.name"
        placeholder="手机号/企业信用代码号"
        prefix-icon="el-icon-user"
      ></el-input>
    </el-form-item>

    <div class="codeWrap">
      <el-form-item prop="code" class="codeInput">
        <el-input
          v-model="ruleForm.code"
          placeholder="验证码"
          prefix-icon="el-icon-document-checked"
        ></el-input>
      </el-form-item>
      <div style="width: 120px; height: 40px; cursor: pointer">
        <img
          :src="computedBaseURl + '/util/imgCaptcha'"
          alt=""
          width="auto"
          height="100%"
          @click="refreshImg"
          ref="verifyImg"
        />
      </div>
    </div>

    <!-- phonecode -->
    <div class="codeWrap">
      <el-form-item prop="phoneCode" class="phoneCodeInput">
        <el-input
          v-model="ruleForm.phoneCode"
          placeholder="动态码"
          prefix-icon="el-icon-phone-outline"
          autoComplete="off"
        ></el-input>
      </el-form-item>
      <el-button
        type="primary"
        plain
        style="width: 100px; height: 42px; padding-right: 0; padding-left: 0"
        @click="getPhoneCode"
        :disabled="disabledBtn"
        v-loading="loading"
        element-loading-text="请查看手机"
        element-loading-spinner="el-icon-loading"
        >获取动态码</el-button
      >
    </div>

    <el-form-item>
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        style="width: 100%"
        >确认登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ["refresh"],
  data() {
    return {
      time: "",
      loading: false,
      disabledBtn: false,
      ruleForm: {
        name: "",
        password: "",
        code: "",
        phoneCode:''
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
        phoneCode: [
          { required: true, message: "请输入动态码", trigger: "blur" },
          {
            min: 1,
            max: 6,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.refreshImg();
  },
  methods: {
    getPhoneCode() {
      this.loading = true;
      this.disabledBtn = true;

      this.axios({
        url: "/api/v1/user/regsms",
        method: "post",
        data: {
          name: this.ruleForm.name,
          code: this.ruleForm.code,
          img_timestamp: this.time,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.loading = false;
          this.disabledBtn = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          setTimeout(function () {
            this.loading = false;
            this.disabledBtn = false;
          }, 60 * 1000);
        }
      });
    },
    refreshImg() {
      var time = new Date().getTime();
      this.time = time;
      this.$refs.verifyImg.src =
        this.computedBaseURl + `/api/v1/util/imgCaptcha?timestamp=` + this.time;
      this.ruleForm.code = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            url: "/api/v1/user/smslogin",
            data: {
              name: this.ruleForm.name,
              phoneCode: this.ruleForm.phoneCode,
              code: this.ruleForm.code,
              img_timestamp: this.time,
            },
            method: "post",
          }).then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              this.refreshImg();
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });

              this.$store.dispatch("name", res.data.result.name).then(() => {
                this.$Cookies.set("name", res.data.result.name, {
                  expires: 7,
                });
              });
              this.$store
                .dispatch("token", res.data.result.access_token)
                .then(() => {
                  this.$Cookies.set("token", res.data.result.access_token, {
                    expires: 7,
                  });
                });
              this.$store.dispatch("isLogin", 1).then(() => {
                this.$Cookies.set("isLogin", "1", { expires: 7 });
                this.$router.push("/");
              });
              this.$store
                .dispatch("userCode", res.data.result.user_code)
                .then(() => {
                  this.$Cookies.set("userCode", res.data.result.user_code, {
                    expires: 7,
                  });
                });
            }
          });
        }
      });
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
  watch: {
    refresh: {
      handler(n, o) {
        this.refreshImg();
      },
    },
  },
};
</script>

<style scoped>
.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 350px;
  justify-content: space-between;
}
.demo-ruleForm {
  margin-top: 12px;
}
</style>