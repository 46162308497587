<template>
  <div>
    <Top1 />
    <div style="border-top: 4px #096 solid">
      <div class="registerBG" :style="wrapImg">
        <el-card class="box-card">
          <div slot="header">
            <span>注册帐号</span>
          </div>
          <!-- form -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="name" style="margin-top: 1px">
              <el-input
                v-model="ruleForm.name"
                placeholder="手机号"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>

            <div class="codeWrap">
              <el-form-item prop="code" class="codeInput">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="验证码"
                  prefix-icon="el-icon-document-checked"
                ></el-input>
              </el-form-item>
              <div style="width: 120px; height: 40px; cursor: pointer">
                <img
                  :src="computedBaseURl + '/api/v1/util/imgCaptcha'"
                  alt=""
                  width="auto"
                  height="100%"
                  @click="refreshImg"
                  ref="verifyImg"
                />
              </div>
            </div>

            <!-- phonecode 6位动态码-->
            <div class="codeWrap">
              <el-form-item prop="phoneCode" class="phoneCodeInput">
                <el-input
                  v-model="ruleForm.phoneCode"
                  placeholder="动态码"
                  prefix-icon="el-icon-phone-outline"
                ></el-input>
              </el-form-item>
              <el-button
                type="primary"
                plain
                style="
                  width: 100px;
                  height: 42px;
                  padding-right: 0;
                  padding-left: 0;
                "
                @click="getPhoneCode"
                :disabled="disabledBtn"
                v-loading="loading"
                element-loading-text="请查看手机"
                element-loading-spinner="el-icon-loading"
                >获取动态码</el-button
              >
            </div>

            <el-form-item prop="password">
              <el-input
                v-model="ruleForm.password"
                placeholder="密码(8-20位包含字母、数字及符号三种组合)"
                prefix-icon="el-icon-orange"
                type="password"
                autoComplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="password2">
              <el-input
                v-model="ruleForm.password2"
                placeholder="确认密码"
                prefix-icon="el-icon-orange"
                type="password"
              ></el-input>
            </el-form-item>

            <el-form-item prop="checked" style="display: flex">
              <el-checkbox v-model="ruleForm.checked"
                >已阅读并同意
              </el-checkbox>
              <el-link
                type="info"
                :underline="false"
                @click="alertAgreement"
                style="margin-top: -3px"
                >《会员协议》</el-link
              >

              <el-link
                type="info"
                :underline="false"
                @click="alertStatement"
                style="margin-top: -3px"
                >《隐私条款声明》</el-link
              >
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                style="width: 100%"
                >确认注册</el-button
              >
              <el-link type="info" :underline="false" @click="toLogin"
                >返回登录</el-link
              >
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    <Foot />

    <!-- dialog -->
    <el-dialog
      title="中惠隐私条款声明"
      :visible.sync="dialogVisible"
      fullscreen
    >
      <div class="dialogContent">
        <p>
          本声明将阐述我们关于隐私权保护的条款，适用于由中惠保险经纪有限公司（以下简称中惠）拥有并运营的各类平台所有产品和服务。您对中惠旗下各类平台的访问和使用，即表示您接受了本隐私声明，并同意任何关于隐私或对本平台使用的争议，均适用本隐私申明。为了更好地提供服务，我们按照合法、合理、安全、保密的原则，依据信息安全及保险法律法规要求，合理收集、管理和使用您的个人信息。若您未能提供相关信息和数据，可能会导致您无法在本平台（含App、微信小程序）开立账户、使用网站功能或享受相关保险服务。
        </p>
        <h4>1.适用范围</h4>
        <p>
          1.1.本隐私条款适用于中惠旗下各类平台所有产品和服务。<br />
          1.2.若您使用中惠平台的服务，即表示您认同我们在本条款中所述内容。在将您的信息用于本隐私权条款未涵盖的用途时，我们会事先征求您的同意。<br />
          1.3.我们不会向任何人出售、交换或透露您的个人信息。<br />
          1.4.我们不会向第三方透露您的个人信息，除了以下的例外情况。<br />
          （1）保险公司、有代理或经纪执照的保险中介：如果您通过中惠旗下平台购买保险产品，您的个人信息将会传递给您所选择的保险公司。如果您申请投保的产品是通过与我们有合作关系的保险中介提供的，我们也可能将您的个人信息提供给相关的保险中介。<br />
          （2）第三方服务提供者：我们聘请其它公司和个人替我们履行某些职能，包括但不限于：处理投保申请、发送邮件或电子邮件、分析数据、提供市场营销帮助、处理付款事项及提供客户服务。他们能接触到为履行职责所需的个人信息，但不能用于其他目的,
          并将对您的个人信息根据合同条例严格保密。<br />
          （3）法律义务：当依法被要求及需要时或当我们相信法律允许时，我们可能会根据法律义务，披露您的个人信息。<br />
          （4）已取得您的书面同意。<br />
          （5）为免除您在生命、身体或财产方面之急迫危险。<br />
          （6）为防止他人权益之危害。<br />
          （7）为增进公共利益，且无害于您的重大利益。<br />
          （8）当政府机关依照法定程序要求本网站披露个人资料时，本网站将根据执法单位之要求或为公共安全之目的提供个人资料。<br />
          除以上提及的情况外，我们不会将您的个人信息与第三方分享。以上情况下之任何披露，本网站均不承担任何责任。<br />
        </p>
        <h4>2.我们收集的个人信息</h4>
        <p>
          根据您与我们之间的关系，我们采集您的个人信息的目的是为了提供更好的服务，我们所收集的有关您的个人信息可能包括：<br />
          2.1.一般信息与联系方式：姓名；地址；电子邮件地址和电话号码；性别；婚姻状况；家庭状况；出生日期；密码；教育背景；身体素质；照片；工作经历、技能与经验；专业执照与隶属机构；与投保人、被保险人之间的关系；以及发生保险事故的时间和原因。<br />
          2.2.政府部门或机构颁发的识别号码：身份证号码；护照号码；社会保险号码；税务识别号码；军官证号码；或驾驶证或其它证件号码。<br />
          2.3.财务信息和账户详情：支付账户和号码；银行账户号码和账户详情；信用记录和信用评分；资产；收入；以及其它支付和财务信息。<br />
          2.4.疾病状况和健康状况：目前或以前的身体、心理或疾病状况；健康状况；受伤或残疾情况；已进行过的手术；个人习惯（如抽烟或饮酒）；处方情况；以及病史。<br />
          2.5.其它信息：在某些情况下，我们会收集有关您的家族病史、继承信息等其他信息。在预防、识别和调查欺诈过程中，我们可能获取有关您的犯罪记录或民事诉讼记录。我们还会获取您主动提供给我们的其他信息。<br />
          2.6.电话录音、在线沟通及投保轨迹：您致电或在线联系我们保险咨询顾问、或者通过移动设备或电脑访问浏览我们公司网站页面，我们将会收集您的电话录音、在线沟通及投保轨迹记录。<br />
          2.7.设备信息：为了提供更好的产品和/或服务并改善用户体验，我们会收集设备属性信息（例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA等），设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表）。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将您的设备信息或电话号码与您的中惠帐户相关联。<br />
          2.8.日志信息：当您使用中惠平台产品和服务时，我们的服务器会自动记录一些信息，例如您对我们的产品和/或服务的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与通讯软件通讯的信息以及访问服务的日期、时间、时长等。<br />
          2.9.位置信息：当您使用具有定位功能的中惠
          平台产品和服务时，当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们可能会收集和处理有关您实际所在位置的信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。我们会使用各种技术进行定位，这些技术包括
          IP 地址、GPS
          以及能够提供相关信息的其他传感器（比如可能会为我们提供附近设备、Wi-Fi
          接入点和基站的信息）。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。<br />
        </p>
        <h4>3.我们如何使用个人信息</h4>
        <p>
          3.1.我们将以如下目的和方式使用您的个人信息：<br />
          3.2.在我们业务过程中与您和其他相关人士进行沟通。<br />
          3.3.向您寄送与我们的保单、合同以及其他相关的重要信息。<br />
          3.4.改善服务品质（例如，致电我们的联系电话时，对来电进行录音或监测）；进行市场研究和分析（包括满意度调查）。为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于另一项服务中向您展示个性化的内容或广告、用于用户研究分析与统计等服务。<br />
          3.5.根据您所表明的营销偏好，向您提供营销信息（包括与所选第三方合作伙伴提供的其他产品和服务有关的信息）。通过使用收集的信息，我们会得以向您提供定制内容，例如向您展现或推荐相关程度更高（而非普遍推送）的搜索结果、信息流或者广告/推广信息结果。<br />
          3.6.用于业务运营管理；各类客户服务；财务会计、收付款项等；IT系统、数据和网站存储、业务备份等；审计、检查等。我们使用收集的信息来提供并改进我们及中惠关联方、合作方提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。为改进我们及中惠关联方、合作方的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，中惠可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的统计类产品。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的大数据分析信息。<br />
          3.7.解决投诉，处理数据访问或资料更正请求。<br />
          3.8.为您提供安全保障。为提高您使用我们与中惠关联方、合作方提供的产品和服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范、诈骗监测、信贷分析等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、条款或规则的行为，以保护您、我们的其他用户、我们或中惠关联方、合作方及社会公众的合法权益。<br />
          3.9.遵守适用的法律法规和监管要求（包括您的居住国法律以外的法律），例如与反洗钱和反恐融资有关的法律法规和监管要求；遵守法律程序以及其他政府机构的要求。<br />
          3.10.当我们要将信息用于本隐私条款未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会实现征求您的同意。<br />
        </p>
        <h4>4.您共享的信息</h4>
        <p>
          我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除中惠及其关联公司外的任何公司、组织和个人分享您的信息：<br />

          在获得您的明确同意后，我们会与其他方共享您的个人信息。我们可能会根据法律法规规定，或按政府主管部门的强制性要求或司法裁定，对外共享您的个人信息。<br />

          在使用中惠平台服务进行交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄露，请您立即联络我们的客服，以便中惠采取相应措施。<br />

          公开信息是指您自愿公开分享的任何信息。任何人都可以在使用和未使用中惠期间查看或访问这些信息。<br />

          为使用中惠平台，可能存在您必须公开分享的信息。您理解并同意，为构建更为诚信、公平的保险交易环境，您的评价信息需要被公开分享。<br />

          请注意，您在使用我们服务时自愿共享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频及其他各种形式的信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开上传相关信息。<br />

          您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方：<br />

          4.1.获得您的同意或授权。<br />

          4.2.根据法律法规的规定或行政、司法机构等有权机关的要求或者授权。<br />

          4.3.出于实现“我们在您使用服务过程中获得的信息”部分所述目的，本隐私条款中的义务和行使我们的权利，向我们的关联方、合作伙伴或代表我们履行某项职能的第三方（代表我们发出推送通知的通讯服务商、处理银行卡的支付机构）分享您的个人信息。<br />

          4.4.您理解并认可，中惠作为平台方，在某些情况下，只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。<br />

          4.5.根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于IPO，债券发行）时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议来要求其对您的个人信息采取合理的保密措施。<br />

          4.6.为维护我们及关联方或其他用户的合法权益。<br />
        </p>
        <h4>5、我们如何保护您个人信息的安全</h4>
        <p>
          我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如银行卡信息）时受
          SSL 加密保护；我们同时对
          中惠旗下平台使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br />

          5.1.我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本条款所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br />

          5.2.互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。<br />

          5.3.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br />

          5.4.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。<br />

          同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br />
        </p>
        <h4>6、我们如何存留您的信息</h4>
        <p>
          我们收集的有关您的信息和资料将保存在我们和/或我们的关联公司的服务器上。<br />

          用于识别您个人身份以及反映相关活动的个人信息将留存至用户注销账户时，除非法律要求或允许在更长的期间内保留这些信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。<br />

          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：<br />

          6.1.访问您的个人信息<br />

          您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：<br />

          账户信息——如果您希望访问或编辑您的账户中的个人基本资料信息、联系方式信息、更改您的密码、添加安全信息、进行账户关联、身份认证等，您可以访问我的账户设置执行此类操作。<br />

          隐私信息——您可以在隐私设置中访问或清楚您的搜索历史记录、查看和修改兴趣以及管理其他数据。<br />

          6.2.更正您的个人信息<br />

          您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过我们提供的联系方式随时与我们联系，提出更正申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。<br />

          6.3.删除您的个人信息<br />

          在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过我们提供的联系方式反馈随时与我们联系：<br />

          (1)如果我们违法法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；<br />

          (2)如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。<br />

          (3)当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br />

          6.4.改变您授权同意的范围<br />

          每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。<br />

          如您想改变授权范围，您可通过中惠平台产品的隐私设置修改授权范围，例如：<br />

          (1)您可以登录账号安全界面解除中惠帐号与第三方帐号的绑定关系，可以在隐私设置中方便地对搜索记录进行开关设置。<br />

          (2)当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。<br />

          6.5.注销您的账户<br />

          您随时可注销此前注册的账户。您可以登录账户安全，注销您的中惠帐号。一旦您注销中惠账号，将无法使用中惠全线用户产品和服务，因此请您谨慎操作。我们为了保护您或他人的合法权益会结合您对中惠各产品和服务的使用情况判断是否支持您的注销请求。<br />

          6.6.提前获知产品和服务停止运营<br />

          中惠愿一直陪伴您，若因特殊原因导致中惠平台产品和服务被迫停止运营，我们将按照法律法规要求在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。<br />

          6.7.响应您的上述请求<br />

          为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />

          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br />

          在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：<br />

          (1)与国家安全、国防安全直接相关的；<br />

          (2)与公共安全、公共卫生、重大公共利益直接相关的；<br />

          (3)与犯罪侦查、起诉、审判和执行判决等直接相关的；<br />

          (4)我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；<br />

          (5)响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br />

          (6)涉及商业秘密的。<br />
        </p>
        <h4>7.我们如何处理未成年人的个人信息</h4>
        <p>
          我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。<br />

          对于经父母或监护人同意而收集的未成年人个人信息情况，我们只会在收到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。<br />

          如果父母或监护人发现我们在未获其同意的情况下收集了未成年人的个人信息，请通过我们提供的联系方式联系我们，我们会设法尽快删除相关数据。<br />
        </p>
        <h4>8、如何使用Cookie 及网络 Beacon</h4>
        <p>
          1、Cookie的使用<br />

          Cookie是由网页服务器存放在您的访问设备上的文本文件。指定给您的Cookie
          是唯一的，它只能被Cookie发布给您的域中的Web服务器读取。<br />

          我们使用 Cookie
          来帮助您实现您的联机体验的个性化，使您在我们及关联方获得更轻松的访问体验。Cookie
          会帮助您在后续访问我们网站时调用您的信息，简化记录您填写个人信息（方便您一键登录、授权登录）的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全。<br />

          您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝
          Cookie。如果您的浏览器启用了 Do Not
          Track，那么我们的所有网站都会尊重您的选择。但您可能无法完全体验所访问的我们网站或某些服务的全部功能。<br />

          2、网络Beacon的使用<br />

          我们网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络
          beacon"），它们可以帮助网站计算浏览网页的用户或访问某些cookie。我们使用网络beacon的方式有：<br />

          （1）我们通过在我们网站上使用网络beacon，计算用户访问数量，并通过访问
          cookie 辨认注册用户。<br />

          （2）我们通过得到的cookie信息，可以在我们网站提供个性化服务。<br />
        </p>
        <h4>9．通知和修订</h4>
        <p>
          我们可能适时修订本条款内容。如该等变更会导致您在本条款项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示、向您发送电子邮件等方式通知您。我们会在本页面上发布对本隐私条款所做的任何变更。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私条款。在前述情况下，若您继续使用我们的服务，即表示同意接受修改后的本条款并受之约束。<br />

          本隐私条款所指的重大变更包括但不限于：<br />

          (1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />

          (2)个人信息共享、转让或公开披露的主要对象发生变化；<br />

          (3)您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />

          (4)其他可能对您的个人信息权益产生重大影响的变化时。<br />
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- dialog1 -->
    <el-dialog title="用户注册协议" :visible.sync="dialog1Visible" fullscreen>
      <div class="dialogContent">
        <h4>1.接受条款</h4>
        <p>
          注册成为中惠用户前，
          确实详细阅读本协议的所有内容，当用户点击“同意注册协议，提交注册”后，即视为同意本协议的所有条款并愿受其约束，成为注。
        </p>

        <p>
          中惠有权对本协议条款进行修改，修改后的协议一旦公布即有效代替原来的协议。用户可随时查阅最新协议。每次协议更新后，用户会在下一次登陆中惠时，得到“同意用户注册协议”的提示。用户应仔细阅读协议，当用户点击“登陆”后，即视为用户同意接受更新版协议
          。如用户不同意更新版协议，可以停止接受中惠依据本协议提供的服务。
        </p>
        <h4>2.服务说明</h4>

        <p>
          中惠通过国际互联网向用户提供丰富的网上资源，包括各种信息工具、网上交流、投保服务、个性化内容等等（以下简称“本服务”）。除非另有其它明示规定，增强或强化目前本服务的任何新功能，包括所推出的新产品，均受到本服务条款之规范。您了解并同意，本服务依“现状”提供，对使用者通讯或个人化设定的时效、删除、传递错误、未予储存、或其他任何问题，中惠均不承担任何责任。为使用本服务，您必须能够自行进入国际互联网，并应自行支付相关服务费用。此外，您必须自行提供与国际联网连线所需之一切必要装备，包括计算机、数据机或其他存取装置。
        </p>

        <p>
          中惠提供的资料仅供参考目的之用。从中惠获得的资讯以及相关意见不应被认为是引诱用户投保或支付。用户应该了解，您所获得的信息均不是强迫提供，这些信息在任何情况下都不是，也不应看作是对用户的投保咨询。
        </p>

        <h4>3.遵守法律</h4>
        <p>
          您同意遵守《中华人民共和国保密法》、《计算机信息系统国际联网保密管理规定》、《中华人民共和国计算机信息系统安全保护条例》、《计算机信息网络国际联网安全保护管理办法》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法等相关法律法规的所有规定，并对您以任何方式使用服务的任何行为及其结果承担全部责任。在任何情况下，如果中惠合理地认为您的行为，包括但不限于您的任何言论和其他行为违反或可能违反上述法律和法规的任何规定，中惠可在任何时候不经事先通知终止向您提供服务。
        </p>

        <h4>4.您的注册义务</h4>

        <p>为了能使用本服务，您同意以下事项∶</p>

        <p>
          (a)依本服务注册表之提示提供注册公司真实、正确、最新及完整的资料（以下简称“登记资料”）。
        </p>
        <p>
          (b)维持更新“登记资料”，确保其真实、正确、最新及完整。若您提供任何错误、不实、过时或不完整的资料，并为中惠所确知；或者中惠有合理的理由怀疑前述资料为错误、不实、过时或不完整，中惠有权暂停或终止您的帐号，并拒绝您于现在和未来使用本服务之全部或部分。
        </p>

        <p>
          (c)个人注册客户服务内容是否适合用户的未成年子女浏览或者使用，由用户加以判断并约束，如因相关服务内容不适合未成年人浏览、使用而导致任何影响或损失，本网站不承担任何责任。
        </p>

        <h4>5.中惠隐私保护</h4>

        <p>您的“登记资料”以及其他特定资料均受到本网站隐私政策保护。</p>
        <h4>6.用户帐号、密码及安全</h4>

        <p>
          维持密码及帐号的机密安全，是您的责任。利用该密码及帐号所进行的一切行动，您应负完全的责任。所衍生之任何损失或损害，本网站无法也不承担任何责任。您同意以下事项：
        </p>

        <p>
          (a)您的密码或帐号遭到未获授权的使用，或者其他任何安全问题发生时，您将立即通知中惠；
        </p>

        <p>
          (b)每次连线完毕，均应正常退出您的帐号使用。您未能依前述指示行事，则有可能得不到中惠在安全方面的保护。
        </p>

        <h4>7.用户行为</h4>

        <p>
          您了解的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其他资料（以下简称“内容”），无论通过公开张贴或私下传送，均为前述“内容”提供者之责任。简而言之，您经由本服务上载、张贴、发送电子邮件或传送之“内容”，完全是您而非中惠的责任。中惠无法控制经由本服务而张贴之“内容”，因此不保证前述“内容”之正确性、完整性或品质。您了解使用本服务时，可能会接触到令人不快、不适当、令人厌恶之“内容”。在任何情况下，中惠均不为任何“内容”负责，包含但不限于任何“内容”之任何错误或遗漏，以及经由本服务张贴、发送电子邮件或传送而衍生之任何损失或损害。但中惠有权删除任何前述内容并停止您使用本服务的全部或部分。
        </p>
        <p>您同意不将本服务作以下使用∶</p>

        <p>
          (a)上载、张贴、发送电子邮件或传送任何非法、有害、胁迫、滥用、骚扰、侵害、中伤、粗俗、猥亵、诽谤、侵害他人隐私、有害或种族歧视的或道德上令人不快的“内容”。
        </p>
        <p>
          (b)冒充任何人或机构，包含但不限于中惠主管、论坛领袖、主持人，或以虚伪不实的方式陈述或谎称与任何人或机构之关联。
        </p>
        <p>
          (c)伪造标题或以其他方式操控识别资料，以伪装通过本服务传送的任何“内容”之来源。
        </p>
        <p>
          (d)将依据任何法律,合约或法定关系无权传送的任何“内容”加以上载、张贴、发送电子邮件或以其他方式传送。
        </p>
        <p>
          (e)将侵害任何人的专利、商标、商业秘密、著作权或其他专属权利（以下简称“权利”）的“内容”加以上载、张贴、发送电子邮件或以其他方式传送。
        </p>
        <p>
          (f)将任何广告信函、促销资料、“垃圾邮件”、“连锁信件”、“直销”或其他任何形式的劝诱资料加以上载、张贴、发送电子邮件或以其他方式传送，专供前述目的使用的区域，不在此限。
        </p>

        <p>
          (g)将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之任何资料，加以上载、张贴、发送电子邮件或以其他方式传送。
        </p>

        <p>
          (h)破坏正常的对话流程、造成屏幕快速移动，或使本服务其他使用者无法打字，或对其他使用者参加即时交流的能力产生负面影响。
        </p>

        <p>
          (i)干扰或破坏本服务或与本服务相连线的服务器和网络，或不遵守本服务连线网络的规定、程序、政策或规范。
        </p>

        <p>(j)故意或非故意违反任何相关当地、国家和国际法。</p>

        <p>(k)“跟踪”或以其他方式骚扰他人。</p>
        <p>(l)收集和储存其他使用者之个人资料，本企业体系内的除外。</p>

        <p>
          您了解中惠未针对“内容”事先加以审查，但中惠及其指定人有权（但无义务）拒绝和删除通过本服务提供的任何“内容”。在不限制前述规定的前提下，中惠及其指定人有权将违反本服务条款和令人反感的任何“内容”加以移除。您使用任何“内容”时，同意必须自行加以评估，承担所有风险。
        </p>
        <p>
          您了解并同意，中惠(依据法律的要求，或基于诚信为了以下目的或在合理必要范围内)认定必须将“内容”加以保存或揭露时，可以保存及揭露：(a)遵守法律程序，(b)执行本服务条款，(c)回应任何“内容”侵害第三人权利之主张，或(d)保护中惠、其使用者及公众之权利、财产或个人安全。您了解本服务之技术处理及传送，包含您的“内容”，可能(a)经由各个网路加以传送，且(b)为了符合及配合连线网路或装置之技术要求而进行改变。
        </p>
        <h4>8.在中惠张贴公共资料</h4>
        <p>
          (a)为适用本服务条款，“本服务公开使用区域”指一般公众可以使用的区域。
        </p>
        <p>
          (b)您选择在“本服务”公开使用区域张贴的“内容”，或在本服务其他任何公开使用区域张贴包含照片或图形之内容，您即授予中惠全球性、免许可费及非独家的使用权，使中惠为展示、散布及推广张贴前述“内容”之特定目的若为照片或图形，则仅为张贴前述照片或图形于本服务之目的(若为照片或图形，则仅为张贴前述照片或图形于本服务之目的为上述之使用)，将前述“内容”在本服务复制、修改、出版。若您选择继续将前述“内容”放入本服务，则本使用权持续有效；若您将前述“内容”自本服务中删除，则本使用权于删除时终止。
        </p>
        <p>
          你选择于本服务其他公开使用区域张贴的其他“内容”，即授予中惠免许可费、永久有效、不可撤销、非独家及可完全再授权之权利，可在全球，使用、复制、修改、发行、翻译、创造衍生性著作，并将前述“内容”（一部或全部）加以散发、表演及展示，及/或放入任何现有和未来开发出的形式、媒体和科技的其他著作物当中。
        </p>
        <h4>9.禁止转售服务</h4>
        <p>
          您同意对本服务任何部分或本服务之使用或获得，不进行复制、拷贝、出售、转售或作任何商业目的的使用。
        </p>
        <h4>10.关于使用及储存的一般措施</h4>
        <p>
          您承认中惠可以订定关于本服务的一般措施及限制。若中惠将本服务维持或传送之任何讯息及其他通讯和其他“内容”删除或未予储存，中惠毋须承担任何责任。您亦同意，中惠有权关闭长时间未使用的帐号。不论通知与否，中惠有权随时变更这些一般措施及限制。
        </p>
        <h4>11.服务修改和终止</h4>
        <p>
          中惠有权于任何时间暂时或永久修改或终止本服务（或其任何部分），无论其通知与否。中惠对您和任何第三人均不承担关于本服务任何修改、暂停或终止之责任。
        </p>
        <p>
          您同意中惠可以因任何理由终止您的密码、帐号（或其任何部分）或本服务之使用，并将您在本服务内任何“内容”加以移除并删除。中惠也可以于通知或未通知之情形下，随时终止本服务或其任何部分。您同意依本服务条款任何规定提供之本服务，无需进行事先通知即得终止，中惠可以立即关闭或删除您的帐号及您帐号中所有相关资料及档案，及/或禁止前述档案和本服务之使用。此外，您同意若本服务之使用被终止，中惠对您或任何第三人均不承担责任。
        </p>

        <h4>12.与广告商进行之交易</h4>

        <p>
          您于本服务或通过本服务与广告商进行通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及前述交易其他任何相关条款、条件、保证或声明，完全为您与前述广告商之间之行为。中惠依其注册地法或本服务条款，毋须承担对广告及广告商进行审查、甄别之义务。前述任何交易或前述广告商出现于本服务所产生的任何性质的损失或损害，中惠不予负责。
        </p>
        <h4>13.链接</h4>
        <p>
          本服务或第三人可提供与其他国际联网上之网站或资源之链接。由于中惠无法控制前述网站及资源，您了解并同意，前述网站或资源是否可供利用，中惠不予负责，存在或源于前开网站或资源之任何“内容”、广告、产品或其他资料，中惠亦不予担保或负责。您进一步同意，因使用或依赖或经由前述任何网站或资源之任何“内容”、商品或服务所产生或据称所产生之任何损害或损失，中惠不承担任何直接或间接责任。
        </p>
        <h4>14.中惠之专属权利</h4>
        <p>
          您了解并同意，本服务及所使用之任何相关必要软件（以下简称“软件”）含有受到相关知识产权及其他法律保护之专有的保密资料。通过本服务或广告商向您呈现之赞助广告或资讯所包含之“内容”，均受到著作权、商标、服务商标、专利或其他专属权利及法律之保护。未经中惠或广告商明示授权，您同意不修改、出租、出借、出售、散布本服务或“软件”之部分或全部，或据以制作衍生著作。中惠授予您个人、不可移转及非专属之使用权，使您得于单机计算机使用其“软件”之目的，您不得（并不得允许任何第三人）复制、修改、创作衍生著作、进行还原工程、反向编译，或以其他方式发现源代码，或出售、转让、再授权或提供“软件”设定担保，或以其他方式移转“软件”之任何权利。您同意不以任何方式修改“软件”，或使用修改后的“软件”。您同意不经由非中惠所提供的界面使用本服务。
        </p>
        <p>
          中惠商标、图案以及其他中惠标志及产品、服务名称，均为中惠公司之商标。未经中惠事先之同意，您同意不以任何方式展示或使用。
        </p>
        <h4>15.免责声明</h4>
        <p>您明确了解并同意：</p>
        <p>
          （a）您使用本服务之风险由您个人负担。中惠依据“现况”及“现有”基础提供服务。中惠明确不提供任何明示或默示的担保。
        </p>
        <p>
          （b）中惠不保证以下事项：(Ⅰ)本服务将符合您的要求，(Ⅱ)本服务不受干扰、及时提供、安全可靠或免于出错，(Ⅲ)本服务之使用权取得的结果正确或可靠，(Ⅳ)您经由本服务购买或取得的任何产品、服务、资讯或其他资料将符合您的期望，且(Ⅴ)“软件”中任何错误都将得到更正。
        </p>
        <p>
          （c）是否通过使用本服务下载或取得任何资料应由您自行考虑且自负风险，因任何资料之下载而导致您电脑系统之任何损坏或资料流失，您应负完全责任。
        </p>

        <p>
          （d）您自中惠或经由本服务取得之建议和资讯，无论其为书面或口头，绝不包含附带或以任何方式构成本服务条款未明示规定之任何保证。
        </p>
        <p>
          （f）保单生效日期如因非可控因素导致不能正常生效，请及时拨打中惠客户服务电话400-616-8833。“非可控因素”包含但不限于互联网故障、停电、网络入侵、硬件故障等。
        </p>
        <p>
          （g）中惠所提供的所有信息据、建议、分析等资料仅供参考，您据此操作而造成所有损失，中惠将不承担任何责任。
        </p>
        <p>
          （h）中惠的帮助信息仅供您操作参考，据此操作而引起的纷争，中惠概不负责。
        </p>
        <p>
          （i）中惠不保证提供的信息与数据等资料的及时性、条理性、准确性、充分性、完整性，不对上述信息与数据可能出现的错误或误导承担任何责任。
        </p>
        <p>
          （j）中惠对进行投保与支付操作时在互联网传输过程中可能出现的系统中断、停顿、延迟、数据错误等情况不承担任何责任。
        </p>
        <p>
          （K）非因我方原因或者由于不可抗力、不可预见等因素导致您不能正常使用本服务或及时地获得本服务所造成的损失，本网站和本网站的信息服务提供商不承担任何责任。
        </p>
        <h4>16.责任限制</h4>
        <p>
          您明确了解并同意，基于以下原因而造成之利润、商誉、使用、资料损失或其他无形损失，中惠不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使中惠已被告知前述赔偿之可能性亦然）：（Ⅰ）本服务之使用或无法使用，（Ⅱ）经由或通过本服务购买或取得之任何商品、资料、资讯或服务，或接收之讯息，或进行的交易所衍生之替代商品及服务的购买成本，（Ⅲ）您的传输或资料遭到未获授权的存取或变造，（Ⅳ）本服务中任何第三人的声明或行为，或（Ⅴ）本服务其他相关事宜。
        </p>

        <p>
          由于您通过本服务提供、张贴或传送的“内容”、您与本服务连线、您违反本服务条款、或您侵害其他人任何权利因而衍生或导致任何第三人提出的索赔或请求(包括合理的律师费)，您同意赔偿中惠及其分公司、关联公司、主管、代理人、联名厂商或其他夥伴及员工，并使其免受损害。
        </p>
        <h4>17.保险相关服务之特别警告</h4>
        <p>
          您同意对本服务任何部分或本服务之使用或获得，不进行复制、拷贝、出售、转售或作任何商业目的的使用。
        </p>
        <p>
          若您希望就网站商情、投保、客户服务成立或加入任何服务，或从本服务接收或要求提供任何消息、信息、警告或其他资料，请再度详阅前述第14、15条。这两条对您加倍适用。本服务纳入的“内容”非供交易或投资目的而使用。经由本服务传送之任何资讯之正确性、适用性和供应，中惠均不予保证，并且不承担任何责任。基于前述资料之任何交易或投资决定，由您自行承担全部后果和责任。
        </p>
        <p>
          在此郑重提醒用户，网上投保仍然存在且不限于以下风险，用户还应充分了解和认识到其存在且不限于以下风险：
        </p>
        <p>
          （a）由于互联网和移动通讯网络数据传输等原因，投保和支付过程可能会出现中断、停顿、延迟、数据错误等情况；
        </p>
        <p>（b）用户账号及密码信息泄露或客户身份可能被仿冒；</p>
        <p>
          （c）由于互联网和移动通讯网络上存在黑客恶意攻击的可能性，网络服务器可能会出现故障及其他不可预测的因素，投保或支付过程可能会出现错误或延迟；
        </p>
        <p>
          （d）用户的网络终端设备及软件系统可能会受到非法攻击或病毒感染，导致无法进行投保、支付或相关操作失败；
        </p>

        <p>
          （e）用户的网络终端设备及软件系统与中惠所提供的系统不兼容，无法进行相关操作；
        </p>
        <p>
          （f）如用户缺乏网上操作经验，可能因操作不当造成操作失败或操作失误；
        </p>
        <p>
          （g）由于网络故障，用户通过本服务进行投保时，用户网络终端设备已显示操作成功，而我方服务器未接到其指令，从而存在投保不成功的风险；用户网络终端设备对其委托未显示成功，于是用户再次发出指令，而我网站已收到用户两次投保指令，并按其指令进行了操作，使投资者由此产生重复投保或支付的风险。
        </p>
        <p>（h）上述风险可能会导致用户发生损失。</p>
        <h4>18.著作权和商标</h4>
        <p>
          中惠尊重他人知识财产，呼吁使用者也要同样尊重知识产权。若您认为您的作品遭到复制之情形已构成著作权之侵害，请提供以下资料予中惠：
        </p>
        <p>（a）有权代理著作权利益所有人之电子或实体签名。</p>
        <p>（b）您主张遭到侵害之作品描述。</p>
        <p>（c）您主张遭侵害您权利之作品位于网站所在位置的叙述。</p>
        <p>（d）地址、电话号码及电子邮件地址。</p>
        <p>
          （e）于诚信认为该有争议的使用未经著作权人、其代理人或法律授权的声明。
        </p>
        <p>
          （f）解做伪证之处罚前提下，声明您通知所载之前资述料均为正确，且您是著作权人或经授权代理著作权人为上述声明。
        </p>
        <p>
          关于著作权侵害主张通知，请拨打中惠客户服务电话400-133-0365或发送邮件至zhonghui@zhonghuicn.com。
        </p>
        <h4>19.违约</h4>
        <p>
          倘发现任何违反本服务条款之事项，请拨打中惠客户服务电话400-133-0365或发送邮件至gxaz@zhonghuicn.com。
        </p>
        <p>20.用户中止条款 中惠保留在任何时候中止任何用户成员资格的权利。</p>
        <h4>21.一般条款</h4>

        <p>
          本服务条款构成您与中惠之全部协议，规范您对于本服务之使用，并取代您先前与中惠所签署之任何协议。您使用相关服务、第三方内容或软件时，亦应符合适用之额外条款及条件。本服务条款及您与中惠之关系，均受到中国法律所管辖，不适用其涉外法律原则。您与中惠均同意接受其个人及专属管辖。中惠未行使或执行本服务条款任何权利或规定，不构成前述权利或权利之抛弃。倘本服务条款任何规定经管辖法院认定无效，当事人依然同意法院应努力使前述规定反映之当事人意向具备效力，且本服务条款其它规定仍应保有完整的效力及效果。本服务条款之标题仅供方便而设，不具任何法律或契约效果。
        </p>

        <p>本协议受中华人民共和国有关法律法规的约束。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog1Visible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Top1 from "@/components/login/Top1.vue";
import Foot from "@/components/login/Foot.vue";
export default {
  components: {
    Top1,
    Foot,
  },
  data() {
    var validatorName = (rule, value, callback) => {
      if (this.ruleForm.name) {
        if (
          isNaN(this.ruleForm.name) ||
          this.ruleForm.name.length != 11 ||
          this.ruleForm.name[0] != 1
        ) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入名称"));
      }
    };
    var validatorCode = (rule, value, callback) => {
      if (this.ruleForm.code) {
        if (this.ruleForm.code.length == 4) {
          callback();
        } else {
          callback(new Error("请输入正确的验证码位数"));
        }
      } else {
        callback(new Error("请输入验证码"));
      }
    };
    var validatorPhoneCode = (rule, value, callback) => {
      if (this.ruleForm.phoneCode) {
        if (this.ruleForm.phoneCode.length == 6) {
          callback();
        } else {
          callback(new Error("请输入正确的动态码位数"));
        }
      } else {
        callback(new Error("请输入动态码"));
      }
    };
    var validatorPassword = (rule, value, callback) => {
      if (this.ruleForm.password) {
        if (
          this.ruleForm.password.length >= 8 &&
          this.ruleForm.password.length <= 20
        ) {
          if (
            this.ruleForm.password.search(/[a-zA-Z]/) == -1 ||
            this.ruleForm.password.search(/[0-9]/) == -1 ||
            this.ruleForm.password.search(/[\W\_]/) == -1
          ) {
            callback(new Error("密码需要包含数字,字母,符号的三种组合"));
          } else {
            callback();
          }
        } else {
          callback(new Error("密码位数需要8-20位之间"));
        }
      } else {
        callback(new Error("请输入密码"));
      }
    };
    var validatorPassword2 = (rule, value, callback) => {
      if (this.ruleForm.password2) {
        if (this.ruleForm.password == this.ruleForm.password2) {
          callback();
        } else {
          callback(new Error("密码不一致"));
        }
      } else {
        callback(new Error("请输入确认密码"));
      }
    };
    var validatorChecked = (rule, value, callback) => {
      if (this.ruleForm.checked) {
        callback();
      } else {
        callback(new Error("请确认阅读《会员协议》"));
      }
    };
    return {
      loading: false,
      dialog1Visible: false,
      disabledBtn: false,
      wrapImg: {
        background:
          "url(" + require("../assets/images/bg_reg.c211fa5.jpg") + ")",
      },
      ruleForm: {
        name: "",
        code: "",
        phoneCode: "",
        password: "",
        password2: "",
        checked: true,
      },
      rules: {
        name: [{ validator: validatorName, trigger: "blur" }],
        code: [{ validator: validatorCode, trigger: "blur" }],
        phoneCode: [{ validator: validatorPhoneCode, trigger: "blur" }],
        password: [{ validator: validatorPassword, trigger: "blur" }],
        password2: [{ validator: validatorPassword2, trigger: "blur" }],
        checked: [{ validator: validatorChecked, trigger: "change" }],
      },
      codeImgUrl: "",
      dialogVisible: false,
      time: "",
    };
  },
  mounted() {
    this.refreshImg();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post("/api/v1/user/register", {
              name: this.ruleForm.name,
              code: this.ruleForm.code,
              phoneCode: this.ruleForm.phoneCode,
              password: this.md5(this.ruleForm.password.trim()),
            })
            .then((res) => {
              if (res.data.code > 0) {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });

                this.$router.push("/login");
              }
            });
        }
      });
    },
    toLogin() {
      this.$router.push("/login");
    },
    alertStatement() {
      this.dialogVisible = true;
    },
    alertAgreement() {
      this.dialog1Visible = true;
    },
    refreshImg() {
      var time = new Date().getTime();
      this.time = time;
      this.$refs.verifyImg.src =
        this.computedBaseURl + `/api/v1/util/imgCaptcha?timestamp=` + this.time;
      this.ruleForm.code = "";
    },
    getPhoneCode() {
      this.loading = true;
      this.disabledBtn = true;

      this.axios
        .post("/api/v1/user/regsms", {
          name: this.ruleForm.name,
          code: this.ruleForm.code,
          img_timestamp: this.time,
          sms_flag: "0",
        })
        .then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.loading = false;
            this.disabledBtn = false;
            this.refreshImg();
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            setTimeout(function () {
              this.loading = false;
              this.disabledBtn = false;
            }, 60 * 1000);
          }
        });
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
};
</script>

<style scoped>
.registerBG {
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
  height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 390px;
}
.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 350px;
  justify-content: space-between;
}
.dialogContent {
  text-align: left;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>