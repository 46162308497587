<template>
  <div>
    <Top1 />
    <div class="wrapMiddle">
      <el-steps
        :space="400"
        :active="activeStep"
        finish-status="success"
        :align-center="true"
        process-status="success"
      >
        <el-step title="验证身份"></el-step>
        <el-step title="修改登录密码"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>

      <div v-if="activeStep == 0" class="wrapForm">
        <el-form
          :model="ruleForm1"
          :rules="rules1"
          ref="ruleForm1"
          class="demo-ruleForm"
        >
          <el-form-item prop="name">
            <el-input
              v-model="ruleForm1.name"
              placeholder="手机号"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>

          <div class="codeWrap">
            <el-form-item prop="code" class="codeInput">
              <el-input
                v-model="ruleForm1.code"
                placeholder="验证码"
                prefix-icon="el-icon-document-checked"
              ></el-input>
            </el-form-item>
            <div style="width: 120px; height: 40px; cursor: pointer">
              <img
                :src="computedBaseURl + '/util/imgCaptcha'"
                alt=""
                width="auto"
                height="100%"
                @click="refreshImg"
                ref="verifyImg"
              />
            </div>
          </div>

          <!-- phonecode -->
          <div class="codeWrap">
            <el-form-item prop="phoneCode" class="phoneCodeInput">
              <el-input
                v-model="ruleForm1.phoneCode"
                placeholder="动态码"
                prefix-icon="el-icon-phone-outline"
                autoComplete="off"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              plain
              style="
                width: 100px;
                height: 42px;
                padding-right: 0;
                padding-left: 0;
              "
              @click="getPhoneCode"
              :disabled="disabledBtn"
              v-loading="loading"
              element-loading-text="请查看手机"
              element-loading-spinner="el-icon-loading"
              >获取动态码</el-button
            >
          </div>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitRuleForm1()"
              style="width: 100%"
              >下一步</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep == 1" class="wrapForm">
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm2"
          class="demo-ruleForm"
        >
          <el-form-item prop="password1">
            <el-input
              v-model="ruleForm2.password1"
              placeholder="输入密码"
              prefix-icon="el-icon-document"
              type="password"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password2">
            <el-input
              v-model="ruleForm2.password2"
              placeholder="密码确认"
              prefix-icon="el-icon-document"
              type="password"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitRuleForm2()"
              style="width: 100%"
              >下一步</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep == 2" class="wrapForm">
        <div style="margin-bottom: 20px; margin-top: 90px; font-size: 40px">
          密码修改成功!
        </div>
        <div>
          <span style="color: #f56c6c; margin-right: 5px">{{ countdown }}</span
          >秒之后自动跳转,
          <el-link
            type="primary"
            @click="toLink()"
            :underline="false"
            style="margin-top: -5px"
            >点击跳转</el-link
          >
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Top1 from "@/components/login/Top1.vue";
import Foot from "@/components/login/Foot.vue";
export default {
  components: {
    Top1,
    Foot,
  },
  data() {
    return {
      loading: false,
      disabledBtn: false,
      activeStep: 0,
      countdown: 20,
      ruleForm1: {
        name: "",
        password: "",
        code: "",
      },
      ruleForm2: {
        password1: "",
        password2: "",
      },
      rules2: {
        password1: [
          {
            validator: this.validatePassword1,
            trigger: "change",
          },
        ],
        password2: [
          {
            validator: this.validatePassword2,
            trigger: "change",
          },
        ],
      },
      rules1: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
        phoneCode: [
          { required: true, message: "请输入动态码", trigger: "blur" },
          {
            min: 1,
            max: 6,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.refreshImg();
  },
  methods: {
    validatePassword1(rule, value, callback) {
      if (this.ruleForm2.password1) {
        if (
          this.ruleForm2.password1.length >= 8 &&
          this.ruleForm2.password1.length <= 20
        ) {
          if (
            this.ruleForm2.password1.search(/[a-zA-Z]/) == -1 ||
            this.ruleForm2.password1.search(/[0-9]/) == -1 ||
            this.ruleForm2.password1.search(/[\W\_]/) == -1
          ) {
            callback(new Error("密码需要包含数字,字母,符号的三种组合"));
          } else {
            callback();
          }
        } else {
          callback(new Error("密码位数需要8-20位之间"));
        }
      } else {
        callback(new Error("请输入密码"));
      }
    },
    validatePassword2(rule, value, callback) {
      if (this.ruleForm2.password2) {
        if (this.ruleForm2.password1 == this.ruleForm2.password2) {
          callback();
        } else {
          callback(new Error("密码不一致"));
        }
      } else {
        callback(new Error("请输入确认密码"));
      }
    },
    refreshImg() {
      var time = new Date().getTime();
      this.time = time;
      this.$refs.verifyImg.src =
        this.computedBaseURl + `/api/v1/util/imgCaptcha?timestamp=` + this.time;
      this.ruleForm1.code = "";
    },
    getPhoneCode() {
      if (this.rules1.name && this.rules1.code) {
        this.loading = true;
        this.disabledBtn = true;

        this.axios({
          url: "/api/v1/user/sendSms",
          method: "post",
          data: {
            name: this.ruleForm1.name,
            code: this.ruleForm1.code,
            img_timestamp: this.time,
            sms_flag: "0",
          },
        }).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.loading = false;
            this.disabledBtn = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            setTimeout(function () {
              this.loading = false;
              this.disabledBtn = false;
            }, 60 * 1000);
          }
        });
      }
    },
    submitRuleForm1() {
      if (this.ruleForm1.code && this.ruleForm1.phoneCode) {
        this.axios({
          url: "/api/v1/user/verifyIdentidy",
          method: "post",
          data: {
            name: this.ruleForm1.name,
            code: this.ruleForm1.code,
            phoneCode: this.ruleForm1.phoneCode,
            sms_flag: "0",
          },
        }).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.next();
          }
        });
      }
    },
    submitRuleForm2() {
      this.$refs["ruleForm2"].validate((valid) => {
        if (valid) {
          this.axios({
            url: "/api/v1/user/changePassword",
            method: "post",
            data: {
              name: this.ruleForm1.name,
              new_password: this.md5(this.ruleForm2.password1.trim()),
              phoneCode: this.ruleForm1.phoneCode,
            },
          }).then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.next();
            }
          });
        }
      });
    },
    next() {
      this.activeStep++;
    },
    toLink() {
      this.$router.push({ name: "Home" });
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
  watch: {
    activeStep: {
      handler(o, n) {
        if (o == 2) {
          var time = setInterval(() => {
            if (this.countdown == 0) {
              clearInterval(time);
              this.toLink();
            }
            if (this.countdown != 0) {
              this.countdown--;
            }
          }, 1000);
        }
      },
    },
  },
};
</script>

<style scoped>
.wrapMiddle {
  width: 1200px;
  margin: 40px auto;
}
.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 350px;
  justify-content: space-between;
}
.demo-ruleForm {
  margin-top: 12px;
}
.wrapForm {
  width: 350px;
  height: 400px;
  margin: 40px auto;
}
</style>