<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-form-item prop="name">
      <el-input
        v-model="ruleForm.name"
        placeholder="手机号/企业信用代码号"
        prefix-icon="el-icon-user"
      ></el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input
        v-model="ruleForm.password"
        placeholder="密码"
        prefix-icon="el-icon-orange"
        type="password"
      ></el-input>
    </el-form-item>

    <div class="codeWrap">
      <el-form-item prop="code" class="codeInput">
        <el-input
          v-model="ruleForm.code"
          placeholder="验证码"
          prefix-icon="el-icon-document-checked"
        ></el-input>
      </el-form-item>
      <div style="width: 120px; height: 40px; cursor: pointer">
        <img
          :src="computedBaseURl + '/api/v1/util/imgCaptcha'"
          alt=""
          width="auto"
          height="100%"
          @click="refreshImg"
          ref="verifyImg"
        />
      </div>
    </div>

    <el-form-item>
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        style="width: 100%"
        >确认登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ["refresh"],
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
        code: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "密码长度在 8 到 20 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "输入了太多的字符",
            trigger: "blur",
          },
        ],
      },

      time: "",
    };
  },
  mounted() {
    this.refreshImg();
  },
  methods: {
    refreshImg() {
      var time = new Date().getTime();
      this.time = time;
      this.$refs.verifyImg.src =
        this.computedBaseURl + `/api/vi/util/imgCaptcha?timestamp=` + this.time;
      this.ruleForm.code = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            url: "/api/v1/user/login",
            data: {
              name: this.ruleForm.name,
              password: this.md5(this.ruleForm.password.trim()),
              code: this.ruleForm.code,
              img_timestamp: this.time,
            },
            method: "post",
          }).then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              this.refreshImg();
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$store.dispatch("name", res.data.result.name).then(() => {
                this.$Cookies.set("name", res.data.result.name, {
                  expires: 7,
                });
              });

              this.$store
                .dispatch("token", res.data.result.access_token)
                .then(() => {
                  this.$Cookies.set("token", res.data.result.access_token, {
                    expires: 7,
                  });
                });

              this.$store
                .dispatch("userCode", res.data.result.user_code)
                .then(() => {
                  this.$Cookies.set("userCode", res.data.result.user_code, {
                    expires: 7,
                  });
                });

              this.$store.dispatch("isLogin", 1).then(() => {
                this.$Cookies.set("isLogin", "1", { expires: 7 });

                this.$router.push("/");
              });
            }
          });
        }
      });
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
  watch: {
    refresh: {
      handler(n, o) {
        this.refreshImg();
      },
    },
  },
};
</script>

<style scoped>
.codeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 350px;
  justify-content: space-between;
}
.demo-ruleForm {
  margin-top: 12px;
}
</style>